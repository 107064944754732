import React, { useState, useEffect } from "react";
import styled from "styled-components";

//
import CarSlider from "../car-slider/CarSlider";
import carList from "../../static/cars";

export default function AppRight() {
  const [cars, setCars] = useState({
    Стандарт: [],
    Минивен: [],
    Кроссовер: [],
    Бизнес: [],
  });

  useEffect(() => {
    getCarList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getCarList() {
    let tempObj = { ...cars };
    carList.cars.forEach((i) => {
      if (i.type === "Электромобиль") {
        tempObj["Бизнес"] = [...tempObj["Бизнес"], i];
      } else {
        tempObj[i.type] = [...tempObj[i.type], i];
      }
    });
    setCars({ ...tempObj });
  }

  return (
    <StyledAppRight className="app__right">
      <div className="car__slider t-l">
        <CarSlider list={cars["Бизнес"]} sliderName="t-l" delay={2200} />
      </div>
      <div className="car__slider t-r">
        <CarSlider
          list={cars["Стандарт"]}
          size="sm"
          sliderName="t-r"
          delay={1200}
        />
      </div>
      <div className="car__slider b-l">
        <CarSlider list={cars["Минивен"]} size="md" sliderName="b-l" />
      </div>
      <div className="car__slider b-r">
        <CarSlider list={cars["Кроссовер"]} sliderName="b-r" delay={1600} />
      </div>
    </StyledAppRight>
  );
}

const StyledAppRight = styled.section`
  flex-grow: 1;
  min-height: 700px;
  position: relative;

  .car__slider {
    position: absolute;

    &.t-l {
      top: 0;
      left: 45px;
    }

    &.t-r {
      top: -20px;
      right: 50px;
      width: 180px;
    }

    &.b-l {
      bottom: -20px;
      left: 20px;
      height: 250px;
      width: 250px;
    }

    &.b-r {
      bottom: 80px;
      right: 10px;
      width: 320px;
      height: 320px;
    }
  }
`;
