import React, { useState, useEffect } from "react";
import styled from "styled-components";
// import { useBeforeunload } from "react-beforeunload";

import logoImg from "../../assets/images/logo.svg";
import Main from "./sections/Main";
import Selection from "./sections/Selection";
import Success from "./sections/Success";
import PhoneBlock from "./sections/PhoneBlock";
import { parsePhoneNumber } from "react-phone-number-input";
import axios from "axios";

export default function FormCard() {
  const [step, setStep] = useState(0);
  const [selectErr, setSelectErr] = useState(false);
  const [phoneErr, setPhoneErr] = useState(false);
  const [form, setForm] = useState({
    period: "",
    trip: "",
    when: "",
    carType: "",
    phone: "",
    country: "",
  });
  const [isConfirm, setIsConfirm] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!selectErr) return;
    setTimeout(() => {
      setSelectErr(false);
    }, 600);
  }, [selectErr]);

  useEffect(() => {
    if (!phoneErr) return;
    setTimeout(() => {
      setPhoneErr(false);
    }, 750);
  }, [phoneErr]);

  function changeStep(s, step = null) {
    if (step) {
      switch (s) {
        case 1:
          window.dataLayer.push({ event: "start_step" });
          break;

        case 2:
          window.dataLayer.push({ event: "how_long", value: step?.period });
          break;

        case 3:
          window.dataLayer.push({ event: "trip", value: step?.trip });
          break;

        case 4:
          window.dataLayer.push({ event: "when_need", value: step?.when });
          break;

        case 5:
          window.dataLayer.push({ event: "car_type", value: step?.carType });
          break;

        default:
          console.log("dataLayer: default");
      }
    }

    if (s > 6) return;
    if (s === 6 && !(form.phone?.length > 7 && form.phone?.length < 15)) {
      setPhoneErr(true);
      return;
    }
    if (s === 6) {
      window.dataLayer.push({ event: "final_step" });
      let formData = {
        how_long: form.period,
        trip_type: form.trip,
        when_need: form.when,
        car_type: form.carType,
        phone: form.phone,
        country: form.country,
      };
      setIsLoading(true);
      axios
        .post(
          "https://api.almak.rent:62230/prokat8/hs/api_qizz/postQuizDubai",
          { ...formData }
        )
        .then((r) => {
          setStep(s);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
      return;
    }

    // next step
    setStep(s);
  }

  function changeStepWithPagination(s) {
    if (s === 2 && form.period === "") {
      setSelectErr(true);
      return;
    }
    if (s === 3 && form.trip === "") {
      setSelectErr(true);
      return;
    }
    if (s === 4 && form.when === "") {
      setSelectErr(true);
      return;
    }
    if (s === 5 && form.carType === "") {
      setSelectErr(true);
      return;
    }
    setStep(s);
  }

  function changeForm(key, value) {
    if (key !== "phone") {
      setForm((p) => ({ ...p, [key]: value }));
      changeStep(step + 1, { [key]: value });
    } else {
      let results = parsePhoneNumber(value ? value : "");
      if (results) {
        let country = results.country;
        let phone = results.nationalNumber;
        setForm((p) => ({ ...p, phone, country }));
      }
    }
  }

  return (
    <StyledFormCard>
      <header className="form__card__header" onClick={() => setStep(0)}>
        <img src={logoImg} alt="dubai-prokat" width="100%" />
      </header>

      <div className="fade__wrapper">
        <div className={"fade__item " + (step === 0 ? "active" : "")}>
          <Main onStart={() => changeStep(1, { start: true })} />
        </div>
        <div
          className={
            "fade__item " + ([1, 2, 3, 4].includes(step) ? "active" : "")
          }
        >
          <Selection
            step={step}
            changeStep={changeStep}
            selectErr={selectErr}
            form={form}
            changeForm={changeForm}
            phoneErr={phoneErr}
            isConfirm={isConfirm}
            setIsConfirm={setIsConfirm}
            changeStepWithPagination={changeStepWithPagination}
          />
        </div>
        <div className={"fade__item " + (step === 5 ? "active" : "")}>
          <PhoneBlock
            err={phoneErr}
            changeForm={changeForm}
            isConfirm={isConfirm}
            setIsConfirm={setIsConfirm}
            isLoading={isLoading}
            changeStep={changeStep}
            step={step}
          />
        </div>
        <div className={"fade__item " + (step === 6 ? "active" : "")}>
          <Success />
        </div>
      </div>
    </StyledFormCard>
  );
}

const StyledFormCard = styled.div`
  background: rgba(255, 255, 255, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0px 0px 76.0723px rgba(22, 26, 32, 0.1);
  border-radius: 60px;
  padding: 40px;
  width: 524px;
  height: 100%;

  .form__card__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 20px;
    padding: 10px 0 0 10px;

    img {
      cursor: pointer;
    }

    a {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      color: #161a20;
      opacity: 0.7;
      text-decoration: none;
    }
  }

  .fade__wrapper {
    height: calc(100% - 40px);
    position: relative;
    height: 100%;

    .fade__item {
      position: absolute;
      width: 100%;
      opacity: 0;
      visibility: hidden;
      transition: 0.3s ease-out;
      z-index: 2;

      &.active {
        z-index: 3;
        transition-delay: 0.3s;
        opacity: 1;
        visibility: visible;
      }
    }
  }

  @media (max-width: 1200px) {
    box-shadow: none;
    border: none;
    background-color: transparent;
    border-radius: 0px;
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 420px;
    margin: 0 auto;
    padding: 48px 0px 48px;
    height: 82vh;
    // min-height: 80vh;

    .form__card__header {
      padding: 0;
      transform: translateY(-32px);
    }

    .fade__item.active {
      height: 100%;
    }
  }
`;
