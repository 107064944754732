import React, { useEffect } from "react";
import styled from "styled-components";

//
import CarCard from "../car-card/CarCard";
import carImg from "../../assets/images/car.png";

export default function CarSlider({ list, size, sliderName, delay = 0 }) {
  useEffect(() => {
    const interval = setInterval(() => {
      changeCarCard();
    }, 5000 + delay);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function changeCarCard() {
    let index = 0;
    let cars = document.querySelectorAll(".car_li" + sliderName);
    cars.forEach((i, idx) => {
      if (i.classList.contains("active")) {
        index = idx;
      }
    });
    cars[index].classList.add("exit");
    setTimeout(() => {
      cars[index].classList.remove("active");
      cars[index].classList.remove("exit");
    }, 2000);

    if (index + 1 === cars.length) {
      cars[0].classList.add("active");
    } else {
      cars[index + 1].classList.add("active");
    }
  }

  return (
    <StyledCarSlider>
      {list.map((i, idx) => (
        <li
          key={i.id}
          className={"car_li" + sliderName + " " + (idx === 0 ? "active" : "")}
        >
          <CarCard
            brand={i.brand}
            model={i.model}
            price={i.price}
            img={i.car_image_url || carImg}
            size={size}
          />
        </li>
      ))}
    </StyledCarSlider>
  );
}

const StyledCarSlider = styled.ul`
  position: relative;

  li {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transform: translate(50%, -30%);
    z-index: 3;
    visibility: hidden;

    &.active {
      transition: 0.3s ease-out;
      opacity: 1;
      transform: translate(0, 0);
      visibility: visible;
      z-index: 5;
    }

    &.exit {
      opacity: 0;
      visibility: visible;
      transform: translate(-50%, 30%);
    }
  }
`;
