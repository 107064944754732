import React from "react";
import styled from "styled-components";

//

export default function BackNavigation({ ...props }) {
  return (
    <StyledBackNavigation {...props}>
      <i className="icon icon-arrow-narrow-right"></i>
    </StyledBackNavigation>
  );
}

const StyledBackNavigation = styled.div`
  display: inline-block;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: -ms-grid;
  display: grid;
  place-items: center;
  transition: 0.3s ease-out;
  cursor: pointer;
  position: absolute;
  left: -10px;
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-64px);

  i {
    transition: 0.3s ease-out;
    width: 24px;
    height: 24px;
    transform: rotate(180deg);
    background-color: white;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.25);
  }
`;
