import React from "react";
import styled, { css, keyframes } from "styled-components";

//
import Option from "../../option/Option";
import Pagination from "../../pagination/Pagination";
import BackNavigation from "../../back-navigation/BackNavigation";

const selectionTitle = {
  1: "Какой тип аренды вас интересует?",
  2: "Какой тип поездки вы планируете?",
  3: "Когда нужен автомобиль?",
  4: "Какой автомобиль вас интересует?",
  5: "Остался послений шаг",
};

export default function Selection({
  step,
  changeStep,
  selectErr,
  changeForm,
  form,
  changeStepWithPagination,
}) {
  return (
    <StyledSelection>
      <h1>{selectionTitle[step]}</h1>
      <ul className="carousel__wrapper">
        <StyledCarouselItem step={step} index={1}>
          <SelectRentalType
            changeForm={changeForm}
            value={form.period}
            err={selectErr}
          />
        </StyledCarouselItem>
        <StyledCarouselItem step={step} index={2}>
          <SelectTripType
            changeForm={changeForm}
            value={form.trip}
            err={selectErr}
          />
        </StyledCarouselItem>
        <StyledCarouselItem step={step} index={3}>
          <SelectWhen
            changeForm={changeForm}
            value={form.when}
            err={selectErr}
          />
        </StyledCarouselItem>
        <StyledCarouselItem step={step} index={4}>
          <SelectCarType
            changeForm={changeForm}
            value={form.carType}
            err={selectErr}
          />
        </StyledCarouselItem>
      </ul>
      <p className={"err__text " + (selectErr ? "show" : "")}>
        Сделайте выбор!
      </p>
      {step !== 1 && <BackNavigation onClick={() => changeStep(step - 1)} />}
      <Pagination step={step} changeStep={changeStepWithPagination} />
    </StyledSelection>
  );
}

const StyledCarouselItem = styled.li`
  position: absolute;
  transition: 0.3s ease-out;
  width: 100%;
  ${({ step, index }) => css`
    transform: translateX(
      ${(step === 5 && index === 4 ? 0 : -((step - index) * 100)) + "%"}
    );
    opacity: ${step === index ? 1 : 0};
  `}
`;

function SelectRentalType({ value, changeForm, err }) {
  return (
    <StyledSelect err={err}>
      <li>
        <Option
          title="Краткосрочный (до месяца)"
          selected={value === "Краткосрочный (до месяца)"}
          onClick={() => changeForm("period", "Краткосрочный (до месяца)")}
        />
      </li>
      <li>
        <Option
          title="Долгосрочный (более месяца)"
          selected={value === "Долгосрочный (более месяца)"}
          onClick={() => changeForm("period", "Долгосрочный (более месяца)")}
        />
      </li>
      <li>
        <Option
          title="Лизинг (аренда с правом выкупа)"
          selected={value === "Лизинг (аренда с правом выкупа)"}
          onClick={() =>
            changeForm("period", "Лизинг (аренда с правом выкупа)")
          }
        />
      </li>
    </StyledSelect>
  );
}

function SelectTripType({ value, changeForm, err }) {
  return (
    <StyledSelect err={err}>
      <li>
        <Option
          title="Бизнес-поездка"
          selected={value === "Бизнес-поездка"}
          onClick={() => changeForm("trip", "Бизнес-поездка")}
        />
      </li>
      <li>
        <Option
          title="Туристическая поездка"
          selected={value === "Туристическая поездка"}
          onClick={() => changeForm("trip", "Туристическая поездка")}
        />
      </li>
      <li>
        <Option
          title="Путешествие по стране"
          selected={value === "Путешествие по стране"}
          onClick={() => changeForm("trip", "Путешествие по стране")}
        />
      </li>
    </StyledSelect>
  );
}

function SelectWhen({ value, changeForm, err }) {
  return (
    <StyledSelect err={err}>
      <li>
        <Option
          title="Сегодня - Завтра"
          selected={value === "Сегодня - Завтра"}
          onClick={() => changeForm("when", "Сегодня - Завтра")}
        />
      </li>
      <li>
        <Option
          title="В течение недели"
          selected={value === "В течение недели"}
          onClick={() => changeForm("when", "В течение недели")}
        />
      </li>
      <li>
        <Option
          title="Планирую на будущее"
          selected={value === "Планирую на будущее"}
          onClick={() => changeForm("when", "Планирую на будущее")}
        />
      </li>
    </StyledSelect>
  );
}

function SelectCarType({ value, changeForm, err }) {
  return (
    <StyledSelect err={err}>
      <li>
        <Option
          title="Седан"
          selected={value === "Седан"}
          onClick={() => changeForm("carType", "Седан")}
        />
      </li>
      <li>
        <Option
          title="Джип / Кроссовер"
          selected={value === "Джип / Кроссовер"}
          onClick={() => changeForm("carType", "Джип / Кроссовер")}
        />
      </li>
      <li>
        <Option
          title="Кабриолет / Спорткар"
          selected={value === "Кабриолет / Спорткар"}
          onClick={() => changeForm("carType", "Кабриолет / Спорткар")}
        />
      </li>
    </StyledSelect>
  );
}

const selectErrAnim = keyframes`
  0%, 100% {
    transform: translateY(0px);
  }
  33% {
    transform: translateY(-20px);
  }
  66% {
    transform: translateY(20px);
  }
`;

const StyledSelect = styled.ul`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 10px;

  ${({ err }) =>
    err &&
    css`
      animation-name: ${selectErrAnim};
      animation-timing-function: ease-out;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      animation-duration: 0.5s;
    `}
`;

const StyledSelection = styled.div`
  // height: 100%;
  padding-top: 16px;
  position: relative;

  & > h1 {
    font-size: 26px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.04em;
    text-align: center;
    color: #161a20;
    // white-space: nowrap;
    margin-bottom: 48px;
    height: 48px;
  }

  .carousel__wrapper {
    height: 272px;
    overflow: hidden;
    position: relative;
    // width: 100vw;
    // max-width: 500px;
    // transform: translateX(-40px);
    margin-bottom: 24px;
  }

  .err__text {
    // position: absolute;
    // bottom: 64px;
    // left: 50%;
    // transform: translate(-50%, -50%);
    text-align: center;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: #ff0000;
    opacity: 0;
    transition: 0.2s ease-out;
    visibility: hidden;

    &.show {
      opacity: 1;
      visibility: visible;
    }
  }

  @media (max-width: 768px) {
    & > h1 {
      font-size: 21px;
      line-height: 26px;
      letter-spacing: 0;
    }
  }
`;
