import React from "react";
import styled, { css } from "styled-components";

import Checkbox from "../../checkbox/Checkbox";
import Button from "../../button/Button";
import IconWrapper from "../../icon-wrapper/IconWrapper";
import BackNavigation from "../../back-navigation/BackNavigation";
import LoaderIndicator from "../../loader-indicator/LoaderIndicator";
import PhoneCustomInput from "../../phone-input/PhoneInput";

export default function PhoneBlock({
  err,
  changeForm,
  isConfirm,
  setIsConfirm,
  step,
  changeStep,
  isLoading,
}) {
  return (
    <StyledPhoneBlock active={isConfirm}>
      <h1>Остался послений шаг</h1>
      <p>
        Отправьте заявку и наши специалисты свяжутся с вами в кратчайшие сроки!
      </p>
      <PhoneCustomInput onChangeValue={(phone) => changeForm("phone", phone)} />
      {/* <Input
        error={err}
        onChange={(phone) => changeForm("phone", phone)}
        step={step}
      /> */}

      <div className="privacy">
        <Checkbox
          checked={isConfirm}
          onChange={() => setIsConfirm((p) => !p)}
        />
        <p>
          Нажимая “Отправить”, вы соглашаетесь с политикой конфиденциальности и
          условиями обработки персональных данных
          {/* <a href="/privacy_policy" target="_blank">
            обработки <br /> персональных данных
          </a> */}
        </p>
      </div>
      <BackNavigation onClick={() => changeStep(step - 1)} />

      <ul className="dots__pagination">
        {err ? <p className="err__text">Укажите номер правильно!</p> : null}
      </ul>
      <Button isDisabled={!isConfirm} onClick={() => changeStep(step + 1)}>
        <p>Отправить</p>
        <IconWrapper bg="#fff">
          {isLoading ? (
            <LoaderIndicator />
          ) : (
            <i className="icon icon-arrow-narrow-right" />
          )}
        </IconWrapper>
      </Button>
    </StyledPhoneBlock>
  );
}

const StyledPhoneBlock = styled.div`
  padding-top: 16px;

  & > h1 {
    font-size: 26px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.04em;
    text-align: center;
    margin-bottom: 20px;
  }

  & > p {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 48px;
    color: #000000;
    opacity: 0.7;
  }

  .dots__pagination {
    display: flex;
    align-items: center;
    gap: 5px;
    position: absolute;
    bottom: 105px;
    left: 50%;
    transform: translate(-50%, -100%);

    & > p {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      color: #ff0000;
      transform: translateY(25%);
    }

    li {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #a717ff;
      opacity: 0.2;

      &:last-of-type {
        opacity: 1;
      }
    }
  }

  .privacy {
    padding-left: 10px;
    margin-top: 33px;
    display: flex;
    gap: 20px;
    align-items: center;
    margin-bottom: 100px;

    p {
      font-size: 8px;
      font-weight: 400;
      line-height: 15px;
      color: #161a20;

      a {
        color: #161a20;
      }

      ${({ active }) =>
        active &&
        css`
          opacity: 1;
        `}
    }
  }

  @media (max-width: 1200px) {
    .privacy {
      p {
        color: white;
      }
    }
  }

  @media (max-width: 450px) {
    & > p {
      font-size: 14px;
    }

    .privacy {
      padding-left: 0;

      p {
        font-size: 8px;
        color: white;
      }
    }
  }
`;
