import { createGlobalStyle } from "styled-components";

//
import "./fonts.css";
import "./colors.css";
import "./icons.css";

export const GlobalStyles = createGlobalStyle`
    *, *::after, *::before {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Montserrat', sans-serif;
        -webkit-tap-highlight-color: transparent;
    }


    ul {
        list-style: none;
    }


    .container {
        max-width: 1360px;
        padding: 0 30px;
        margin: 0 auto;
        min-height: 100vh;
        @media (max-width:420px){
            overflow-y:auto;
        }
    }


    .container-sm {
        max-width: 1200px;
        padding: 0 30px;
        margin: 0 auto;
    }

    .mx-auto {
        margin: 0 auto;
    }
`;
