import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";

//
import carImg from "../../assets/images/car.png";
import ContentLoader from "../content-loader/ContentLoader";
import LoaderIndicator from "../loader-indicator/LoaderIndicator";

export default function CarCard({ size, brand, model, price, img = carImg }) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <StyledCarCard size={size}>
      {isLoading ? (
        <ContentLoader size={size} />
      ) : (
        <h1>
          <span>{brand}</span> {model}
        </h1>
      )}

      {isLoading && (
        <div className="car__loader__indicator">
          <LoaderIndicator
            w={size === "md" ? 50 : size === "sm" ? 40 : 60}
            h={size === "md" ? 50 : size === "sm" ? 40 : 60}
            size={size === "md" ? 5 : size === "sm" ? 4 : 6}
            car={1}
          />
        </div>
      )}

      <img
        src={img}
        alt="carImg"
        style={{ visibility: isLoading ? "hidden" : "visible" }}
      />
      <footer>
        <div className="car__price">
          {isLoading ? (
            <ContentLoader price={1} size={size} />
          ) : (
            <h4>от {price} USD.</h4>
          )}
          {isLoading ? <ContentLoader day={1} size={size} /> : <p>в сутки</p>}
        </div>
      </footer>
    </StyledCarCard>
  );
}

const StyledCarCard = styled.div`
  display: inline-block;
  width: 320px;
  height: 320px;
  border-radius: 70px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 71.3103px rgba(54, 255, 255, 0.1);
  border-radius: 70px;
  transform: rotate(-30deg);
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  transition: 0.2s linear;
  padding-top: 25px;

  h1 {
    font-size: 22px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    z-index: 2;
    width: max-content;
    margin: 0 auto;

    span {
      font-weight: 600;
    }
  }

  .car__loader__indicator {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & > img {
    position: absolute;
    transform: rotate(30deg);
    top: 60px;
    left: -100px;
    width: 400px;
    transition: 0.2s linear;
  }

  &:hover {
    transform: rotate(-30deg) scale(0.95);

    & > img {
      transform: rotate(30deg) scale(1.08) translateX(-4%);
    }
  }

  footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    height: 85px;
    border-radius: 0 0 70px 0;
    overflow: hidden;
    z-index: 2;

    .car__price {
      width: max-content;
      height: max-content;
      color: #161a20;
      text-align: center;
      background: #ffffff;
      box-shadow: 0px 0px 15px rgba(22, 26, 32, 0.1);
      border-radius: 50px 0px 0px 0px;
      padding: 15px 40px;

      h4 {
        font-size: 20px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 0em;
      }

      p {
        font-size: 13px;
        font-weight: 300;
        line-height: 16px;
        letter-spacing: 0em;
      }
    }
  }

  ${({ size }) =>
    size === "md"
      ? css`
          width: 250px;
          height: 250px;
          border-radius: 55px;
          box-shadow: 0px 0px 55.7201px rgba(54, 255, 255, 0.1);
          padding-top: 20px;

          h1 {
            font-size: 18px;
            line-height: 21px;
          }

          & > img {
            width: 300px;
            top: 60px;
            left: -60px;
          }

          footer {
            border-radius: 0 0 55px 0;

            .car__price {
              padding: 13px 30px;
              border-radius: 40px 0px 0px 0px;

              h4 {
                font-size: 16px;
                line-height: 19px;
              }

              p {
                font-size: 10px;
                line-height: 12px;
              }
            }
          }
        `
      : size === "sm"
      ? css`
          width: 180px;
          height: 180px;
          border-radius: 40px;
          box-shadow: 0px 0px 39.56px rgba(54, 255, 255, 0.1);
          padding-top: 15px;

          h1 {
            font-size: 12px;
            line-height: 15px;
          }

          & > img {
            width: 210px;
            top: 45px;
            left: -40px;
          }

          footer {
            border-radius: 0 0 40px 0;

            .car__price {
              padding: 9px 22.5px;
              border-radius: 28px 0px 0px 0px;

              h4 {
                font-size: 11px;
                line-height: 14px;
              }

              p {
                font-size: 7px;
                line-height: 9px;
              }
            }
          }
        `
      : null}
`;
