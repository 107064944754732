import React from "react";
import styled, { css } from "styled-components";

export default function Checkbox({ checked, onChange }) {
  return (
    <StyledCheckbox checked={checked} onClick={onChange}>
      <i className="icon icon-check" />
    </StyledCheckbox>
  );
}

const StyledCheckbox = styled.div`
  width: 16px;
  min-width: 16px;
  height: 16px;
  background: #30bdb24a;
  border-radius: 4px;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: 0.3s ease-out;

  i {
    width: 12px;
    height: 12px;
  }
  &:hover {
    background: #30bdb240;

    i {
      background: #161a20;
    }
  }

  ${({ checked }) =>
    checked &&
    css`
      background: #30bdb2;

      i {
        background-color: #fff;
      }

      &:hover {
        background: #30bdb2;

        i {
          background-color: #fff;
        }
      }
    `}
`;
