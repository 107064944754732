import {useState} from 'react';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import './styles.css';

function PhoneCustomInput({onChangeValue, error}) {
  // `value` will be the parsed phone number in E.164 format.
  // Example: "+12133734253".
  const [value, setValue] = useState();

  const onChangePhone =(value)=>{
    if(error) console.log(error);
    else{
        setValue(value);
        onChangeValue(value);
    }
    
  }
  return (
    <PhoneInput
      placeholder="Ваш номер телефона"
      value={value}
      defaultCountry='RU'
      onChange={onChangePhone}
      
      />
  )
}

export default PhoneCustomInput;