function importAll(r) {
  return r.keys().map(r);
}

const carImages = importAll(
  require.context("../assets/images/cars", false, /\.(png|jpe?g|svg)$/)
);

let carList = [];
const carTypes = ["Стандарт", "Минивен", "Бизнес", "Кроссовер"];

carImages.forEach((carImage) => {
  let carPrice = carImage.split("_").pop().split(".")[0];
  let carBrand = carImage.split("_").shift().split("/").pop();
  let carModel = carImage.split("_")[1];
  let carType = carTypes[Math.floor(Math.random() * carTypes.length)];

  carList.push({
    id: Math.floor(Math.random() * 1000),
    type: carType,
    model: carModel,
    brand: carBrand,
    price: carPrice,
    car_image_url: carImage,
  });
});

export default {
  count: carList.length,
  cars: carList,
};
