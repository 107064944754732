import React from "react";
import styled from "styled-components";

//
import successImg from "../../../assets/images/success-circle.png";

export default function Success() {
  return (
    <StyledSuccess>
      <img src={successImg} alt="success-circle" width="160px" />
      <h1>Готово!</h1>
      <p>
        Заявка успешно отправлена, ожидайте <br />
        звонка менеджера в течении 30 мин.
      </p>
      <br />
      <br />

      <a href="/" className="home">
        На главную
      </a>
    </StyledSuccess>
  );
}

const StyledSuccess = styled.div`
  padding-top: 16px;
  text-align: center;

  .home {
    color: #fff;
    font-weight: 500;
    color: #0c58fe;
    text-decoration: none;
    background: #fff;
    padding: 10px 20px;
    border-radius: 12px;
  }
  & > h1 {
    font-size: 40px;
    font-weight: 700;
    line-height: 49px;
    letter-spacing: 0.04em;
    margin: 40px 0 20px;
    color: #161a20;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: #000000;
    opacity: 0.7;
  }

  @media (max-width: 1200px) {
    & > h1 {
      color: white;
    }

    p {
      color: white;
      opacity: 1;
    }
  }
`;
