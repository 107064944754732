import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";

import {
  OS,
  currentBrowser,
  getMobileOperatingSystem,
} from "../../utils/platform";

export default function Button({
  type = "button",
  children,
  block,
  isDisabled,
  ...props
}) {
  const [isHover, setIsHover] = useState(false);

  let step = 1;
  let forward = true;
  useEffect(() => {
    if (!checkDevice()) return;
    if (isHover) return;
    let interval = setInterval(() => {
      changeStepForward();
    }, 40);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHover]);

  function changeStepForward() {
    if (step === 50) {
      forward = false;
    }
    if (step === 1) {
      forward = true;
    }
    if (forward) {
      step += 1;
    } else {
      step -= 1;
    }
  }

  function onHover() {
    setIsHover(true);
  }

  function offHover() {
    setIsHover(false);
    step = 1;
    forward = true;
  }

  function checkDevice() {
    const os = OS(window);
    const browser = currentBrowser(window);
    const mobileOs = getMobileOperatingSystem();

    if (os === "MacOS" && browser === "Safari") return false;
    if (mobileOs === "iOS") return false;
    return true;
  }

  return (
    <StyledButton
      type={type}
      block={block}
      isHover={isHover}
      onMouseOver={onHover}
      onMouseOut={offHover}
      isStatic={!checkDevice()}
      isDisabled={isDisabled}
      {...props}
    >
      {checkDevice() && (
        <>
          <div className="purple"></div>
          <div className="primary" data-mode="forward"></div>
          <div className="danger" data-mode="forward"></div>
        </>
      )}

      <div className="children">{children}</div>
    </StyledButton>
  );
}

const StyledButton = styled.button`
  padding: 20px 20px 20px 40px;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  position: relative;
  transition: all 150ms ease-in-out;
  background-color: rgb(255 123 56);
  overflow: hidden;
  width: 100%;
  min-height: 100px;


  .children {
    transform: translateX(-0.1%);
    display: -ms-grid;
    display: grid;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    grid-template-columns: auto 60px;
    gap: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 40px;
    z-index: 3;
    width: calc(100% - 60px);

    p {
      font-size: 22px;
      font-weight: 500;
      line-height: 27px;
      letter-spacing: 0em;
      white-space: nowrap;
      color: #fff;
      text-align: center;
    }
  }

  ${({ isStatic }) =>
    isStatic &&
    css`
      background: radial-gradient(
          38.07% 151.5% at 43.21% 0%,
          rgb(220 171 58 / 94%) 0%,
          rgb(48 189 177 / 0%) 100%
        ),
        radial-gradient(
          35.36% 148.5% at 82.14% 50%,
          rgb(255 123 56) 0%,
          rgb(48 189 177 / 40%) 100%
        ),
        radial-gradient(
          50% 210% at 0% 50%,
          rgb(48 189 177) 0%,
          rgb(255 123 56) 100%
        ),
        #ffffff;

      box-shadow: 0px 0px 76.0723px rgba(22, 26, 32, 0.05);
      background-color: transparent;
      position: relative;
    `}

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.6;
      pointer-events: none;
      cursor: not-allowed;
    `}

  .danger,
  .purple,
  .primary {
    position: absolute;
    filter: blur(40px);
  }

  .danger {
    background: rgb(220 171 58 / 94%);
    height: 135px;
    width: 220px;

    animation-name: danger-animation;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
    animation-timing-function: ease-in;

    ${({ isHover }) =>
      isHover &&
      css`
        animation: none;
        top 30px;
        left: 60px;
      `}
  }

  @keyframes danger-animation {
    from {
      top: -65px;
      left: 25px;
    }
    to {
      top 30px;
      left: 120px;
    }
  }
 

  .purple {
    background: rgb(48 189 177);
    height: 167px;
    width: 260px;

    animation-name: purple-animation;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
    animation-timing-function: ease-out;

    ${({ isHover }) =>
      isHover &&
      css`
        animation: none;
        top: -30px;
        left: -40px;
      `}
  }

  @keyframes purple-animation {
    from {
      top: -30px;
      left: -40px;
    }
    to {
      top 0px;
      left: 0px;
    }
  }
 
  .primary {
    background: rgb(255 123 56);
    height: 265px;
    width: 180px;
    animation-name: primary-animation;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
    animation-timing-function: ease-in-out;


    ${({ isHover }) =>
      isHover &&
      css`
        animation: none;
      `}
  }

  @keyframes primary-animation {
    from {
      top: 0px;
      left: 130px;
    }
    to {
      top -50px;
      left: 90px;
    }
  }
 
  &:active {
    outline: none;
  }

  @media (max-width : 768px) {
    padding: 20px 15px 20px 20px;
    min-height: 90px;

    .children {
      gap: 10px;
      left: 20px;
      width: calc(100% - 35px);

      p {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
`;
