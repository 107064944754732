import React from "react";
import styled from "styled-components";

//
import Button from "../../button/Button";
import IconWrapper from "../../icon-wrapper/IconWrapper";
import introImg from "../../../assets/images/intro-icon.png";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";

export default function Main({ onStart }) {
  const { width } = useWindowDimensions();

  return (
    <StyledMain>
      <h1>
        <span>Автопрокат</span> <br /> в Дубай
      </h1>
      {/* <div className="model__badge">У нас более 70 моделей в автопарке!</div> */}
      <div className="delivery__wrapper">
        <p>
          Уже 7 лет мы занимаемся автопрокатом в Дубай.
          <br />
          <br />
          С Dubai Rent вы выбираете надежность, скорость и простоту аренды
          автомобиля.
          <br />
          <br />
          Пройдите квиз, и мы подберем для вас идеальный вариант из более чем 70
          моделей!
        </p>
      </div>
      <Button onClick={onStart}>
        <p>Подобрать автомобиль</p>
        <IconWrapper bg="#fff">
          <i className="icon icon-search" />
        </IconWrapper>
      </Button>
    </StyledMain>
  );
}

const StyledMain = styled.div`
  padding-top: 16px;

  @media (max-width: 600px) {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-around;
  }

  & > h1 {
    font-size: 34px;
    font-weight: 600;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 30px;

    span {
      font-weight: 900;
      text-transform: uppercase;
      font-size: 46px;
      font-weight: 900;
      line-height: 56px;
      letter-spacing: 1px;
    }
  }

  .delivery__wrapper {
    border: 1px solid rgba(22, 26, 32, 0.1);
    border-radius: 30px;
    background-color: #30bdb2b0;
    color: white;

    padding: 16px 20px 16px 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 48px;

    p {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0em;
      // opacity: 0.8;
    }
  }

  .model__badge {
    width: max-content;
    margin: 0 auto;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    color: rgb(255 255 255 / 90%);
    background: rgb(48 189 177 / 60%);
    border-radius: 20px;
    padding: 10px 20px;
    margin-bottom: 32px;
  }

  @media (max-width: 768px) {
    & > h1 {
      font-size: 26px;
      line-height: 32px;

      span {
        font-size: 36px;
        line-height: 44px;
      }
    }

    .delivery__wrapper {
      padding: 16px 20px;
      border-radius: 25px;

      p {
        font-size: 14px;
        line-height: 18px;
      }
    }

    .model__badge {
      font-size: 14px;
      padding: 7px 15px;
    }
  }
`;
