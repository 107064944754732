import React from "react";
import styled, { css } from "styled-components";

export default function IconWrapper({
  w = 60,
  h = 60,
  bg,
  children,
  border,
  color,
  rounded = "circle",
  p = null,
}) {
  return (
    <StyledIconWrapper
      w={w}
      h={h}
      rounded={rounded}
      bg={bg}
      border={border}
      color={color}
      style={!p ? { width: w + "px", height: h + "px" } : undefined}
      p={p}
    >
      {children}
    </StyledIconWrapper>
  );
}

const StyledIconWrapper = styled.div`
  background: ${({ bg }) => bg};
  padding: ${({ p }) => p}px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;

  @media (max-width: 768px) {
    transform: scale(0.9);
  }

  ${({ border }) =>
    border &&
    css`
      border: 1px solid #161a20;
    `}

  ${({ color }) =>
    color === "light"
      ? css`
          border-color: #fff;
        `
      : css``}


  ${({ rounded }) =>
    rounded === "circle"
      ? css`
          border-radius: 50%;
        `
      : css`
          border-radius: ${rounded + "px"};
        `}
`;
