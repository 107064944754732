import React from "react";
import styled, { css, keyframes } from "styled-components";

export default function LoaderIndicator({ w = 24, h = 24, size = 3, car }) {
  return <StyledLoaderIndicator w={w} h={h} size={size} car={car} />;
}

const loaderIndicatorAnim = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

const StyledLoaderIndicator = styled.div`
  display: inline-block;
  border: 1px solid #366eff;
  border-radius: 50%;
  border-top-color: transparent;
  animation-name: ${loaderIndicatorAnim};
  animation-timing-function: linear;
  animation-duration: .75s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;

  ${({ w, h, size }) => css`
    width: ${w}px;
    height: ${h}px;
    border-width: ${size}px;
  `}

  ${({ car }) => car && css`
    border-color: #E4E4E5;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: transparent;
  `}
`;
