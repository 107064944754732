import React from "react";
import styled from "styled-components";

export default function Pagination({ step, changeStep }) {
  let pag_list = new Array(5).fill("").map((_, idx) => idx + 1);

  function getClassPagItem(i) {
    if (step === i) return "current";
    if (step > i) return "selected";
    return "";
  }

  return (
    <StyledPagination>
      {pag_list.map((i) => (
        <li
          key={i}
          className={getClassPagItem(i)}
          onClick={() => {
            console.log("page-", i);
            changeStep(i);
          }}
        >
          {i}
        </li>
      ))}
    </StyledPagination>
  );
}

const StyledPagination = styled.ul`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 10px;

  li {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: -ms-grid;
    display: grid;
    place-items: center;
    background: rgba(22, 26, 32, 0.03);
    cursor: pointer;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    color: rgba(22, 26, 32, 0.1);
    transition: background 0.3s ease-out, color 0.3s ease-out;
    border: 2px solid transparent;

    &.selected {
      border-color: #ff7d38;
      color: #fff;
      background: #ff7d38;
    }

    &:hover {
      background: #ff7d38;
      color: #fff;
    }

    &.current {
      background: linear-gradient(45deg, #ff7d38 10%, #30bcb1 100%);
      color: #ffffff;
      border-width: 0;
    }
  }

  @media (max-width: 450px) {
    li {
      width: 50px;
      height: 50px;
      font-size: 18px;
    }
  }
`;
