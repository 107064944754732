import React from "react";
import styled, { css } from "styled-components";

//
import IconWrapper from "../icon-wrapper/IconWrapper";

export default function Option({ title, selected, ...props }) {
  return (
    <StyledOption selected={selected} {...props}>
      <IconWrapper
        bg="transparent"
        border={1}
        p={6}
        color={selected ? "light" : "dark"}
      >
        <i className="icon icon-check" />
      </IconWrapper>
      <p>{title}</p>
    </StyledOption>
  );
}

const StyledOption = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 20px;
  border-radius: 35px;
  padding: 15px;
  cursor: pointer;
  border: 1px solid #ff7c39;
  background: #ffffffad;

  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    color: #161a20;
  }

  &:hover {
    background: #ffffffbc;
  }

  @media (max-width: 768px) {
    padding: 15px 12px;

    p {
      font-size: 18px;
    }
  }

  ${({ selected }) =>
    selected &&
    css`
      background: #ff7c39;

      i {
        background-color: #fff;
      }

      p {
        color: #fff;
      }

      &:hover {
        background: #ff7c39;
      }
    `}
`;
