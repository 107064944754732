import React from "react";
import styled from "styled-components";

import FormCard from "../components/form-card/FormCard";
import AppRight from "../components/app-right/AppRight";
import dBgLeft from "../assets/images/d-bg-l.png";
import dBgRight from "../assets/images/d-bg-right.png";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import dubaiBg from "../assets/images/dubai-bg.png";

export default function Main() {
  const { width } = useWindowDimensions();
  return (
    <StyledMain>
      <div className="container">
        <main className="app__main">
          <section className="app__left">
            <FormCard />
          </section>
          {width > 1200 ? <AppRight /> : null}
        </main>
      </div>
    </StyledMain>
  );
}

const StyledMain = styled.div`
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  background: url(${dubaiBg}) no-repeat;
  background-size: cover;
  background-position: left;

  @media (max-width: 600px) {
    background-position-x: -100vw;
  }

  .dBgLeft {
    position: absolute;
    // background: url(${dBgLeft}) no-repeat center;
    background-size: cover;
    height: 840px;
    width: 1116px;
    left: 0px;
    bottom: 20px;
    filter: blur(80px);
  }

  .dBgRight {
    position: absolute;
    // background: url(${dBgRight}) no-repeat center;
    background-size: cover;
    right: -50px;
    top: 200px;
    transform: rotate(-17.53deg);
    width: 820px;
    height: 620px;
    filter: blur(50px);
  }

  .app__main {
    // min-height: calc(100vh - 140px);
    transform: translateY(1px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 100px 0;
    gap: 70px;
  }

  .app__left {
    height: 714px;
  }

  @media (max-width: 1200px) {
    .dBgRight {
      opacity: 0.5;
      filter: blur(185px);
      transform: rotate(-124.35deg);
      right: -323px;
      top: -100px;
    }

    .dBgLeft {
      opacity: 0.5;
    }

    .app__main {
      justify-content: center;
      padding: 32px 0;
    }
  }

  @media (max-width: 600px) {
    .app__left {
      height: auto;
    }
  }

  @media (max-width: 768px) {
    .dBgRight,
    .loader__wrapper {
      display: none;
    }

    .dBgLeft {
      bottom: 300px;
    }

    .app__main {
      padding-bottom: 0px;
      justify-content: flex-start;
      align-items: flex-start;
      max-height: auto;

      .app__left {
        width: 100%;
      }
    }
  }
`;
