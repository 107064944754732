import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from 'react-router-dom'

//
import App from "./App";
import ScrollToTop from "./components/scroll-to-top/ScrollToTop";
import { GlobalStyles } from "./assets/styles/global";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ScrollToTop />
    <GlobalStyles />
    <App />
  </BrowserRouter>
);
