import React from 'react'
import styled, { css, keyframes } from 'styled-components'

export default function ContentLoader({ size, price, day }) {
    return (
        <StyledContentLoader size={size} price={price} day={day} >
            <div className='anim__rectangle' ></div>
        </StyledContentLoader>
    )
}

const rectAnim = keyframes`
    0% {
        left : -40%;
    }
    70%, 100% {
        left: 100%;
    }
`

const StyledContentLoader = styled.div`
    margin: 0 auto;
    width: ${({ size }) => size === 'md' ? 150 : size === 'sm' ? 120 : 185}px;
    height: 25px;
    height: ${({ size }) => size === 'sm' ? 20 : 25}px;
    background: #161A2005;
    border-radius: 8px;
    position: relative;
    overflow: hidden;

    .anim__rectangle {
        width: 20px;
        height: 60px;
        position: absolute;
        background: rgba(255, 255, 255, 0.5);
        top: 50%;
        transform: rotate(45deg) translateY(-70%);
        animation-name: ${rectAnim};
        animation-duration: 1s;
        animation-iteration-count: infinite;
    }

    ${({ price, size }) => price && css`
        width: ${size === 'md' ? 105 : size === 'sm' ? 75 : 140}px;
        height: ${size === 'md' ? 18 : size === 'sm' ? 12 : 25}px;
        margin-bottom: 4px;
    `}

    ${({ day, size }) => day && css`
        width: 70px;
        height: 12px;
        width: ${size === 'md' ? 55 : size === 'sm' ? 45 : 70}px;
        height: ${size === 'md' ? 10 : size === 'sm' ? 8 : 12}px;
        margin-bottom: 4px;
    `}
`
